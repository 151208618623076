<template>
	<div class="components-sale-order">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-input-search style="width: 400px;" v-model:value="search.key" placeholder="输入订单编号/申请人联系电话" enter-button @search="getSaleOrderList(1,info.limit)" />
		</div>
		<div class="el-content">
			<a-tabs v-model:activeKey="search.status" @change="getSaleOrderList(1,info.limit)">
			    <a-tab-pane key="0" tab="全部"></a-tab-pane>
			    <a-tab-pane key="1" tab="待处理"></a-tab-pane>
			    <a-tab-pane key="9" tab="已完成"></a-tab-pane>
			</a-tabs>
			<a-table class="tb-overflow" row-key="id" :pagination="false" :data-source="info.list" :columns='[
				{title:"售后编号",dataIndex:"order_number",width:200},
				{title:"信息",dataIndex:"order_info",slots:{customRender:"order_info"},width:200},
				{title:"店铺信息",dataIndex:"shop",slots:{customRender:"shop"},width:200},
				{title:"退款信息",dataIndex:"price",slots:{customRender:"price"},width:240},
				{title:"售后类型",dataIndex:"type",slots:{customRender:"type"},width:150},
				{title:"收货状态",dataIndex:"goods_status",slots:{customRender:"goods_status"},width:150},
				{title:"状态",dataIndex:"status",slots:{customRender:"status"},width:150},
				{title:"操作",dataIndex:"action",slots:{customRender:"action"},width:150},
			]' :scroll="{x:1}">
				<template #order_info="{record}">
					<div class="cso-goods" v-if="record.order_info && record.images.length == 0">
						<img class="cso-goods-li" v-if="record.order_info.detail" :src="record.order_info.detail[0].cover" alt="">
						<div class="cso-goods-name" v-if="record.order_info.detail">
							<div>{{record.order_info.detail[0].goods_name}}</div>
							<div> x{{record.order_info.detail[0].count}}</div>
						</div>
					</div>
					<div v-else class="cso-goods">
						<a-image v-for="(val,ind) in record.images" :key="ind" class="cso-goods-li" :src="val"/>
					</div>
				</template>
				<template #shop="{record}">
					<div><span class="f12">店铺：</span>{{record.shop.name}}</div>
					<div><span class="f12">电话：</span>{{record.mobile}}</div>
				</template>
				<template #price="{record}">
					<div><span class="f12">退款金额：</span><span class="kdred">{{record.price}}元</span></div>
					<div><span class="f12">售后原因：</span>{{record.reason}}</div>
				</template>
				<template #type="{record}">
					<a-tag color="warning" v-if="record.type == 1">仅退款</a-tag>
					<a-tag color="#FF0066" v-if="record.type == 2">退货退款</a-tag>
				</template>
				<template #goods_status="{record}">
					<a-tag color="#00CC66" v-if="record.goods_status == 2">已收到货</a-tag>
					<a-tag color="#FF0066" v-else>未收到货</a-tag>
				</template>

				<template #status="{record}">
					<a-tag color="#999" v-if="record.status == 1">待处理</a-tag>
					<a-tag color="#FF0066" v-if="record.status == 2">商家已拒绝</a-tag>
					<a-tag color="#00CC66" v-if="record.status == 3">商家已同意</a-tag>
					<a-tag color="#FF0066" v-if="record.status == 4">申请平台接入</a-tag>
					<a-tag color="#FF0066" v-if="record.status == 5">平台拒绝</a-tag>
					<a-tag color="#00CC66" v-if="record.status == 6">平台同意</a-tag>
					<a-tag color="#0099FF" v-if="record.status == 7">已发货</a-tag>
					<a-tag color="#00CC66" v-if="record.status == 9">已完结</a-tag>
					<a-tag color="#999" v-if="record.status == 10">过期自动放弃</a-tag>
				</template>
				<template #action="{record}">
					<a-button type="primary"
                              @click="showDeal(record)"
                              v-has="{action:'wxapp_order_sale_detail',plat:isShop}">查看详情
                    </a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getSaleOrderList(info.page,e)}"
					@change="(e)=>{getSaleOrderList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal title="售后订单处理"
                 v-model:visible="show.deal"
                 width="600px"
                 :footer="null"
                 @cancel="show.deal = false">
			<div class="sale-order-deal" v-if="form && form.order">
				<div class="sod-goods" >
					<div class="sod-goods-top">
						<div>
							<span>订单编号</span>
							<span style="color: #177CE3;" v-if="form.order.order_info">{{form.order.order_info.order_number}}</span>
						</div>
						<div>
							<span>订单金额</span>
							<span style="color: #E74C3C;">{{form.order.price}}</span>
						</div>
					</div>
					<template v-if="form.order.order_info">
						<div class="sod-goods-info" v-for="(item,index) in form.order.order_info.detail" :key="index">
							<img :src="item.cover" alt="">
							<div>
								<div>{{item.goods_name}}</div>
								<div v-if="item.snap_goods_spec">
									<span v-for="(val,ind) in item.snap_goods_spec.sku" :key="ind">
                                        {{val.spec_value}} ;
                                    </span>
									<span> 数量：x {{item.count}}</span>
								</div>
								<div>数量：x {{item.count}}</div>
							</div>
						</div>
					</template>
				</div>

				<div class="sod-li">
					<div>售后原因：</div>
					<div>{{form.order.reason}}</div>
					<div class="sod-li-phone">
						<i class="el-icon-phone-outline"></i>
						{{form.order.mobile}}
					</div>
				</div>

				<div class="sod-li">
					<div>详细描述：</div>
					<div>
						<span>{{form.order.title}}</span>
						<div class="sod-li-img" >
							<a-image v-for="(val,ind) in form.order.images"
                                     class="sod-li-img-li"
                                     :key="ind"
                                     :src="val" />
						</div>
					</div>
				</div>
				<div class="sod-li" v-if="form.order.msg">
					<div>商家备注：</div>
					<div>{{form.order.msg}}</div>
				</div>
				<div class="sol-deal" v-if="form.order.status == 1">
					<div><span style="color: #F44336;">*</span>处理方式：</div>
					<div>
						<a-select style="width: 400px;" v-model:value="form.type">
							<a-select-option value="1">同意退款</a-select-option>
							<a-select-option value="2">拒绝退款</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="sol-deal" v-if="form.order.status == 1">
					<div><span style="color: #F44336;">*</span>处理说明：</div>
					<div>
						<a-textarea style="width: 400px;" v-model:value="form.msg" placeholder="处理说明" :rows="4" />
					</div>
				</div>
				<a-space v-if="form.order.status == 1" style="margin-left:80px;">
					<a-button type="primary" @click="submitSale">确认</a-button>
					<a-button @click="show.deal = false">取消</a-button>
				</a-space>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import goodsModel from '@/api/goods.js'
export default{
	name:'sale-order',
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{ key:'', status:'0', },
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			show:{ deal:false, },
			form:null,
		})
		getSaleOrderList(1,_d.info.limit)
		function getSaleOrderList(page,limit){
			goodsModel.getSaleOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}

		function showDeal(row){
			_d.form ={
				id:row.id,
				type:'1',
				msg:'',
				order:row,
			}
			_d.show.deal = true
		}

		const submitSale =()=>goodsModel.dealSaleOrder(_d.form.id,_d.form.type,_d.form.msg,()=>{
			getSaleOrderList(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			getSaleOrderList,
			showDeal,
			submitSale
		}
	}
}
</script>

<style lang="scss">
	.cso-goods{
		display: flex;

		.cso-goods-li{
			width: 40px;
			height: 40px;
			margin-right: 6px;
		}
		font-size: 14px;

		.cso-goods-name{
			>div{
				width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	// 售后订单处理
	.sale-order-deal{
		.sod-goods{
			width: 90%;
			background: #f4f4f4;
			border: 1px solid #e4e4e4;
			padding: 12px;
			border-radius: 8px;
			margin-bottom: 16px;
			&-top{
				display: flex;
				justify-content: space-between;
				color: #999;
			}
			&-info{
				display: flex;
				margin-top: 12px;

				img{
					width: 50px;
					height: 50px;
					margin-right: 12px;
				}
			}
		}

		.sod-li{
			display: flex;
			padding: 8px 0;

			div:first-child{
				color: #999;
			}

			.sod-li-img{
				display: flex;
				flex-wrap: wrap;
				margin-top: 12px;

				.sod-li-img-li{
					width: 50px;
					height: 50px;
					margin-right: 6px;
				}
			}
			.sod-li-phone{
				color: #177CE3;
				position: absolute;
				margin-left: 420px;
			}
		}
		.sol-deal{
			display: flex;
			margin: 12px 0;
		}
	}
</style>
